<template>
    <div class="shop">
        <form v-if="!successMessage && isCartItems()" @submit.prevent="validateForm">
            <div class="header">
                <p class="selected-category">{{ translations.title }}</p>
                <span>{{ translations.description }}</span>
                <p class="warning">{{ translations.option_delivery }}</p>
                <div class="radio-group">
                    <label class="radio-option">
                        <input type="radio" v-model="selectedOption" value="pickup" />
                        <span class="custom-radio"></span>
                        {{ translations.pickup }}
                    </label>
                    <label class="radio-option">
                        <input type="radio" v-model="selectedOption" value="delivery" />
                        <span class="custom-radio"></span>
                        {{ translations.delivery }}
                    </label>
                </div>
                <p ref="radioErrorElement" v-if="radioError && !selectedOption" class="error-message">{{
                    translations.errors_form.option_delivery }}</p>
                <p>{{ translations.pickup_address_title }}</p>
                <p>{{ translations.pickup_address }}</p>
                <p class="warning">{{ translations.set_delivery_pickup_date }}</p>
                <div class="delivery-pickup-form">
                    <div class="form-grid">
                        <div class="form-group">
                            <Datepicker :placeholder="translations.delivery_date" :class="$style.input"
                                v-model="cartStore.add_information.deliveryDate"
                                :inputFormat="translations.input_format" :locale="locale" :lowerLimit="new Date()" />
                            <p ref="radioErrorElement" v-if="radioError && !cartStore.add_information.deliveryDate"
                                class="error-message">{{
                                    translations.errors_form.date_delivery }}</p>
                        </div>
                        <div class="form-group">
                            <div class="custom-time-picker">
                                <input type="text" v-model="cartStore.add_information.deliveryTime"
                                    @focus="showDeliveryDropdown = true" @blur="hideDeliveryDropdown"
                                    placeholder="HH:MM" />
                                <ul v-if="showDeliveryDropdown" class="dropdown">
                                    <li v-for="time in times" :key="time" @mousedown.prevent="selectDeliveryTime(time)">
                                        {{ time }}
                                    </li>
                                </ul>
                                <p ref="radioErrorElement" v-if="radioError && !cartStore.add_information.deliveryTime"
                                    class="error-message">{{ translations.errors_form.time_delivery }}</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <Datepicker class="calendar" v-model="cartStore.add_information.pickupDate"
                                :placeholder="translations.pickup_date" :class="$style.input"
                                :inputFormat="translations.input_format" :locale="locale" :lowerLimit="deliveryDate" />
                            <p ref="radioErrorElement" v-if="radioError && !cartStore.add_information.pickupDate"
                                class="error-message">{{
                                    translations.errors_form.date_pickup }}</p>
                        </div>
                        <div class="form-group">
                            <div class="custom-time-picker">
                                <input type="text" v-model="cartStore.add_information.pickupTime"
                                    @focus="showPickupDropdown = true" @blur="hidePickupDropdown" placeholder="HH:MM" />
                                <ul v-if="showPickupDropdown" class="dropdown">
                                    <li v-for="time in times" :key="time" @mousedown.prevent="selectPickupTime(time)">
                                        {{ time }}
                                    </li>
                                </ul>
                                <p ref="radioErrorElement" v-if="radioError && !cartStore.add_information.pickupTime"
                                    class="error-message">{{ translations.errors_form.time_pickup }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <p class="warning">{{ translations.data_personal }}</p>
                <div class="form-container">
                    <div class="form-grid">
                        <div class="form-group">
                            <input type="text" id="firstName" v-model="firstName" :placeholder="translations.name" />
                            <p ref="radioErrorElement" v-if="radioError && !firstName" class="error-message">
                                {{ translations.errors_form.name }}</p>
                        </div>
                        <div class="form-group">
                            <input type="text" id="lastName" v-model="lastName" :placeholder="translations.surname" />
                            <p ref="radioErrorElement" v-if="radioError && !lastName" class="error-message">{{
                                translations.errors_form.surname }}</p>
                        </div>
                        <div class="form-group full-width">
                            <input type="text" id="companyName" v-model="companyName"
                                :placeholder="translations.company" />
                        </div>
                        <div class="form-group full-width">
                            <input type="text" id="companyNip" :placeholder="translations.nip" v-model="companyNip" />
                        </div>
                        <div class="form-group full-width">
                            <input type="text" id="companyAddress" :placeholder="translations.company_address"
                                v-model="companyAddress" />
                        </div>
                        <div class="form-group">
                            <input type="text" id="city" :placeholder="translations.city" v-model="city" />
                            <p ref="radioErrorElement" v-if="radioError && !city" class="error-message">{{
                                translations.errors_form.city }}</p>
                        </div>
                        <div class="form-group">
                            <input type="text" id="postalCode" :placeholder="translations.code_postal"
                                v-model="postalCode" />
                            <p ref="radioErrorElement" v-if="radioError && !postalCode" class="error-message">{{
                                translations.errors_form.code_postal }}</p>
                        </div>
                        <div class="form-group">
                            <input type="email" id="email" :placeholder="translations.email" v-model="email" />
                            <p v-if="radioError &&  !email" class="error-message">{{ translations.errors_form.email }}
                            </p>
                        </div>
                        <div class="form-group">
                            <input type="text" id="phoneNumber" :placeholder="translations.number"
                                v-model="phoneNumber" />
                            <p v-if="radioError &&  !phoneNumber" class="error-message">{{
                                translations.errors_form.number }}</p>
                        </div>
                    </div>
                </div>
                <p class="warning">{{ translations.data_delivery }}</p>
                <div class="form-container">
                    <div class="form-grid">
                        <div class="form-group">
                            <input type="text" id="firstNameDelivery" :placeholder="translations.name"
                                v-model="firstNameDelivery" :disabled="selectedOption !== 'delivery'" />
                            <p ref="firstNameDeliveryError" v-if="radioError && !firstNameDelivery && selectedOption === 'delivery'"
                                class="error-message">{{ translations.errors_form.name_delivery }}</p>
                        </div>
                        <div class="form-group">
                            <input type="text" id="lastNameDelivery" :placeholder="translations.surname"
                                v-model="lastNameDelivery" :disabled="selectedOption !== 'delivery'" />
                            <p ref="surNameDeliveryError" v-if="radioError && !lastNameDelivery && selectedOption === 'delivery'" class="error-message">
                                {{ translations.errors_form.surname_delivery }}</p>
                        </div>
                        <div class="form-group full-width">
                            <input type="text" id="companyNameDelivery" :placeholder="translations.company"
                                v-model="companyNameDelivery" :disabled="selectedOption !== 'delivery'" />
                        </div>
                        <div class="form-group full-width">
                            <input type="text" id="companyNameDelivery" :placeholder="translations.address_delivery"
                                v-model="companyNameDelivery" :disabled="selectedOption !== 'delivery'" />
                        </div>
                        <div class="form-group">
                            <input type="text" id="cityDelivery" :placeholder="translations.city" v-model="cityDelivery"
                                :disabled="selectedOption !== 'delivery'" />
                            <p ref="radioErrorElement" v-if="radioError && !cityDelivery && selectedOption === 'delivery'" class="error-message">{{
                                translations.errors_form.city_delivery }}</p>
                        </div>
                        <div class="form-group">
                            <input type="text" id="postalCodeDelivery" :placeholder="translations.code_postal"
                                v-model="postalCodeDelivery" :disabled="selectedOption !== 'delivery'" />
                            <p ref="radioErrorElement" v-if="radioError && !postalCodeDelivery && selectedOption === 'delivery'" class="error-message">{{
                                translations.errors_form.code_postal_delivery }}</p>
                        </div>
                    </div>
                </div>
                <div class="checkbox-group">
                    <label class="custom-checkbox">
                        <input type="checkbox" v-model="rodo_accept_option" />
                        <span class="checkmark"></span>
                        {{ translations.rodo_accept }}
                    </label>
                    <p v-if="radioError && !rodo_accept_option" class="error-message">{{
                        translations.errors_form.rodo_accept }}</p>
                    <label class="custom-checkbox">
                        <input type="checkbox" v-model="statute_accept_option" />
                        <span class="checkmark"></span>
                        {{ translations.statute_accept }}
                    </label>
                    <p v-if="radioError && !statute_accept_option" class="error-message">{{
                        translations.errors_form.statute_accept }}</p>
                </div>
                <div class="cart-summary">
                    <div class="summary">
                        <p class="sum">{{ translations.sum }}</p>
                        <p class="price">{{ cartStore.totalNet }} {{ translations.currency }}</p>
                        <p class="price">{{ cartStore.totalBrutto }} {{ translations.currency }}</p>
                    </div>
                    <button type="submit">{{ translations.subbmit }}</button>
                </div>
            </div>
        </form>
        <div class="success_message" v-if="successMessage && !isCartItems()">
            <div class="success-content">
                <p class="success-title">{{ translations.success.title }}</p>
                <p class="success-subtitle">{{ translations.success.desc }}</p>
                <p class="success-subtitle-1">{{ translations.success.desc_1 }}</p>
                <button @click="redirectToHome" class="success-button">{{ translations.success.button }}</button>
            </div>
            <div class="success-image">
                <img src="@/assets/shop/success.webp" alt="Obraz przedstawiający zapytanie wysłane">
            </div>
        </div>
        <div v-else-if="!successMessage && !isCartItems()" class="success_message">
            <div class="success-content">
                <p class="success-title">{{ translations.error.title }}</p>
                <p class="success-subtitle">{{ translations.error.desc }}</p>
            </div>
            <div class="success-image">
                <img src="@/assets/shop/success.webp" alt="Obraz przedstawiający zapytanie wysłane">
            </div>
        </div>
    </div>
    <AppFooter />
</template>

<script>
import translationsData from '@/assets/text_lang/translations.json';
import { useLanguageStore } from '@/theme';
import { useCartStore } from '../../store/cart';

import { ref, computed, watch } from 'vue';

import { pl, enUS } from 'date-fns/locale';
import Datepicker from 'vue3-datepicker';
import AppFooter from './FootBar.vue';

import axios from 'axios';

export default {
    name: 'SummaryCartPage',
    components: {
        Datepicker,
        AppFooter
    },
    data() {
        return {
            selectedOption: '', // Przechowywanie wybranej opcji
            additionalInfo: '', // Przechowywanie wartości textarea
            firstName: '',
            firstNameDelivery: '',
            lastName: '',
            lastNameDelivery: '',
            companyName: '',
            companyNameDelivery: '',
            companyNip: '',
            companyAddress: '',
            city: '',
            cityDelivery: '',
            postalCode: '',
            postalCodeDelivery: '',
            email: '',
            phoneNumber: '',

            rodo_accept_option: false,
            statute_accept_option: false,

            deliveryDate: null,
            pickupDate: null,

            showDeliveryDropdown: false,
            showPickupDropdown: false,
            times: this.generateTimeOptions(),

            radioError: '',

            successMessage: false,
        };
    },
    setup() {
        const languageStore = useLanguageStore();
        const cartStore = useCartStore();
        const translations = computed(() => {
            return translationsData[languageStore.currentLanguage]["cart_summary"];
        });
        const currentLanguage = computed(() => languageStore.currentLanguage);
        const locale = ref(enUS);
        watch(currentLanguage, (newLang) => {
            if (newLang === 'pl') {
                locale.value = pl;
            } else {
                locale.value = enUS;
            }
        }, { immediate: true });

        return {
            translations,
            cartStore,
            locale,
        };
    },
    computed: {
        translatedQuantity() {
            return this.quantity === 1 ? this.translations.pc : this.translations.pcs;
        },
    },
    methods: {

        updateDeliveryFieldsState() {
            if (this.selectedOption !== 'delivery') {
                this.firstNameDelivery = '';
                this.lastNameDelivery = '';
                this.companyNameDelivery = '';
                this.cityDelivery = '';
                this.postalCodeDelivery = '';
            }
        },
        redirectToHome() {
            this.successMessage = false;
            this.$router.push('/'); // Przekierowanie do strony głównej
        },
        generateTimeOptions() {
            const options = [];
            for (let h = 0; h < 24; h++) {
                for (let m = 0; m < 60; m += 30) {
                    const hour = String(h).padStart(2, '0');
                    const minute = String(m).padStart(2, '0');
                    options.push(`${hour}:${minute}`);
                }
            }
            return options;
        },
        selectDeliveryTime(time) {
            this.cartStore.add_information.deliveryTime = time;
            this.showDeliveryDropdown = false;
        },
        hideDeliveryDropdown() {
            setTimeout(() => {
                this.showDeliveryDropdown = false;
            }, 200);
        },
        selectPickupTime(time) {
            this.cartStore.add_information.pickupTime = time;
            this.showPickupDropdown = false;
        },
        hidePickupDropdown() {
            setTimeout(() => {
                this.showPickupDropdown = false;
            }, 200);
        },
        isCartItems() {
            return this.cartStore.items.length > 0;
        },
        validateForm() {
            let valid = true;
            this.radioError = '';
            if (!this.selectedOption) {
                this.radioError = "error";
                valid = false;
                this.$nextTick(() => {
                    if (this.$refs.radioErrorElement) {
                        this.$refs.radioErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    }
                });
            }
            if (!this.cartStore.add_information.deliveryDate) {
                this.radioError = 'Proszę wybrać datę dostawy.';
                valid = false;
                this.$nextTick(() => {
                    if (this.$refs.radioErrorElement) {
                        this.$refs.radioErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    }
                });
            }
            if (!this.cartStore.add_information.deliveryTime) {
                this.radioError = 'Proszę wybrać godzinę dostawy.';
                valid = false;
                this.$nextTick(() => {
                    if (this.$refs.radioErrorElement) {
                        this.$refs.radioErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    }
                });
            }
            if (!this.cartStore.add_information.pickupDate) {
                this.radioError = 'Proszę wybrać datę odbioru.';
                valid = false;
                this.$nextTick(() => {
                    if (this.$refs.radioErrorElement) {
                        this.$refs.radioErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    }
                });
            }
            if (!this.cartStore.add_information.pickupTime) {
                this.radioError = 'Proszę wybrać godzinę odbioru.';
                valid = false;
                this.$nextTick(() => {
                    if (this.$refs.radioErrorElement) {
                        this.$refs.radioErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    }
                });
            }
            console.log("firstName", this.firstName);
            if (!this.firstName) {
                this.radioError = 'Proszę podać imię.';
                valid = false;
                this.$nextTick(() => {
                    if (this.$refs.radioErrorElement) {
                        this.$refs.radioErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    }
                });
            }
            if (!this.lastName) {
                this.radioError = 'Proszę podać nazwisko.';
                valid = false;
                this.$nextTick(() => {
                    if (this.$refs.radioErrorElement) {
                        this.$refs.radioErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    }
                });
            }
            if (!this.city) {
                this.radioError = 'Proszę podać miasto.';
                valid = false;
                this.$nextTick(() => {
                    if (this.$refs.radioErrorElement) {
                        this.$refs.radioErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    }
                });
            }
            if (!this.postalCode) {
                this.radioError = 'Proszę podać kod pocztowy.';
                valid = false;
                this.$nextTick(() => {
                    if (this.$refs.radioErrorElement) {
                        this.$refs.radioErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    }
                });
            }
            if (!this.email) {
                this.radioError = 'Proszę podać adres e-mail.';
                valid = false;
                this.$nextTick(() => {
                    if (this.$refs.radioErrorElement) {
                        this.$refs.radioErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    }
                });
            }
            if (!this.phoneNumber) {
                this.radioError = 'Proszę podać numer telefonu.';
                valid = false;
                this.$nextTick(() => {
                    if (this.$refs.radioErrorElement) {
                        this.$refs.radioErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    }
                });
            }

            console.log("selectedOption ========= >", this.selectedOption);
            if (this.selectedOption === 'delivery') {
                console.log("firstNameDelivery", this.firstNameDelivery);
                if (!this.firstNameDelivery) {
                    this.radioError = 'Proszę podać imię.';
                    valid = false;
                    this.$nextTick(() => {
                        if (this.$refs.radioErrorElement) {
                            this.$refs.radioErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                        }
                    });
                }

                if (!this.lastNameDelivery) {
                    this.radioError = 'Proszę podać nazwisko.';
                    valid = false;
                    this.$nextTick(() => {
                        if (this.$refs.radioErrorElement) {
                            this.$refs.radioErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                        }
                    });
                }

                if (!this.cityDelivery) {
                    this.radioError = 'Proszę podać miasto.';
                    valid = false;
                    this.$nextTick(() => {
                        if (this.$refs.radioErrorElement) {
                            this.$refs.radioErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                        }
                    });
                }

                if (!this.postalCodeDelivery) {
                    this.radioError = 'Proszę podać kod pocztowy.';
                    valid = false;
                    this.$nextTick(() => {
                        if (this.$refs.radioErrorElement) {
                            this.$refs.radioErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                        }
                    });
                }
            }



            if (!this.rodo_accept_option) {
                this.radioError = 'Proszę zaakceptować wszystkie wymagane zgody.';
                valid = false;
                this.$nextTick(() => {
                    if (this.$refs.radioErrorElement) {
                        this.$refs.radioErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    }
                });
            }
            if (!this.statute_accept_option) {
                this.radioError = 'Proszę zaakceptować wszystkie wymagane zgody.';
                valid = false;
                this.$nextTick(() => {
                    if (this.$refs.radioErrorElement) {
                        this.$refs.radioErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    }
                });
            }


            if (valid) {
                this.sendAdditionalInfo();
            }
        },
        openTimePicker(event) {
            event.target.showPicker(); // Wymusza otwarcie wbudowanego wyboru czasu
        },
        totalBrutto(price, quantity, days) {
            const net = this.totalNet(price, quantity, days) * 1.23;
            return net.toFixed(2); // Dodanie 23% do ceny netto
        },
        async sendAdditionalInfo() {
            // Przygotowanie payloadu z danymi formularza

            const cartDetails = this.cartStore.items.map(item => {
                return `Produkt: ${item.name || 'Brak nazwy'}
        - Ilość: ${item.quantity || 1}
        - Cena za sztukę (netto): ${item.rental_price || 'Brak danych'}
        - Ilość dni: ${item.days || 1}`;
            }).join('\n\n');


            const payload = {
                subject: 'Zapytanie o możliwość wypożyczenia produktów', // Ustalony temat wiadomości
                message: `Szczegóły zapytania:


        - Imię: ${this.firstName}
        - Nazwisko: ${this.lastName}
        - Email: ${this.email}
        - Numer telefonu: ${this.phoneNumber}
        - Adres dostawy: ${this.city}, ${this.postalCode}
        - Data dostawy: ${this.deliveryDate ? this.deliveryDate.toLocaleDateString() : 'Nie podano'}
        - Godzina dostawy: ${this.cartStore.add_information.deliveryTime || 'Nie podano'}
        - Data odbioru: ${this.pickupDate ? this.pickupDate.toLocaleDateString() : 'Nie podano'}
        - Godzina odbioru: ${this.cartStore.add_information.pickupTime || 'Nie podano'}
        
        Szczegóły dostawy:
        - Imię (dostawa): ${this.firstNameDelivery || 'Nie podano'}
        - Nazwisko (dostawa): ${this.lastNameDelivery || 'Nie podano'}
        - Miasto (dostawa): ${this.cityDelivery || 'Nie podano'}
        - Kod pocztowy (dostawa): ${this.postalCodeDelivery || 'Nie podano'}


             Zawartość koszyka:                                          
        ---------------------------------------------------------------------
        ${cartDetails}
        
        
        Dziękujemy za rozważenie mojego zapytania.`,
            };

            try {
                // Wysłanie danych do API
                await axios.post('https://l4887ivvna.execute-api.eu-central-1.amazonaws.com/dev/email', payload);
                console.log('Dziękujemy za zapytanie. Informacje zostały wysłane.');

                // Wyczyszczenie pól po udanym wysłaniu
                this.selectedOption = '';
                this.deliveryDate = null;
                this.pickupDate = null;
                this.firstName = '';
                this.lastName = '';
                this.email = '';
                this.phoneNumber = '';
                this.firstNameDelivery = '';
                this.lastNameDelivery = '';
                this.city = '';
                this.postalCode = '';
                this.cityDelivery = '';
                this.postalCodeDelivery = '';
                this.subject = '';
                this.message = '';
                this.cartStore.clearCart();

                this.successMessage = true;

            } catch (error) {
                console.error('Error sending request for product rental:', error);
            }
        }

    }
};
</script>

<style module>
input {
    font-family: 'MyFont', sans-serif;
    text-align: left;
    padding-left: 10px;
    text-transform: uppercase;
    box-sizing: border-box;
    width: 100%;
    /* height: 40px; */
    font-size: 10pt;
    border: 2px solid var(--dark-color-background);
    background-color: var(--dark-color-font);
    border-radius: 2px;
}
</style>


<style scoped>
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}



body,
html {
    height: 100%;
    overflow-x: hidden;
}

.shop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 250px;
    box-sizing: border-box;
    background-color: var(--dark-color-font);
    color: var(--dark-color-background);
    width: 100%;
    height: 100%;
    padding-left: 30px;
    padding-right: 30px;
}

.shop .header {
    width: 90%;
    max-width: 1100px;
    text-align: left;
    font-size: 8pt;
    white-space: pre-line;
}

.shop .header .selected-category {
    color: var(--dark-color-background);
    margin: 0;
    margin-bottom: 40px;
    font-size: 14pt;
}

.shop .header span {
    color: var(--dark-color-background);
}

.shop .header p {
    margin-top: 20px;
    margin-bottom: 20px;
}

.shop .header .warning {
    padding-top: 20px;
    color: var(--dark-color-red);
}

input:disabled {
    background-color: rgba(0, 0, 0, 0.1);
    /* Półprzezroczysty szary */
    color: rgba(0, 0, 0, 0.5);
    /* Przygaszony kolor tekstu */
    border-color: rgba(0, 0, 0, 0.3);
    /* Przygaszona obwódka */
    cursor: not-allowed;
    /* Zmiana kursora na "zakaz" */
}


.radio-group {
    display: flex;
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 40px;
}

.radio-option {
    display: flex;
    align-items: center;
}

.radio-option input[type="radio"] {
    display: none;
}

.custom-radio {
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 2px solid var(--dark-color-background);
    border-radius: 2px;
    margin-right: 8px;
    position: relative;
    cursor: pointer;
}

.radio-option input[type="radio"]:checked+.custom-radio {
    background-color: var(--dark-color-background);
}

.custom-radio::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    background-color: var(--dark-color-background);
    transform: translate(-50%, -50%);
    opacity: 0;
}

.radio-option input[type="radio"]:checked+.custom-radio::after {
    opacity: 1;
}

.checkbox-group {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
}

.custom-checkbox {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    font-size: 11px;
    user-select: none;
    min-height: 24px;
}

.custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: var(--dark-color-font);
    border: 2px solid var(--dark-color-background);
    margin-right: 10px;
    flex-shrink: 0;
}

.custom-checkbox input:checked~.checkmark {
    background-color: var(--dark-color-background);
}

::v-deep .v3dp__popout {
    left: 2px;
    width: 100% !important;
    max-width: none !important;
}

::v-deep .v3dp__input_wrapper {
    width: 100%;
}

::v-deep .v3dp__input_wrapper input{
    width: 100%;
}

.delivery-pickup-form {
    width: 100%;
    max-width: 1400px;
    font-size: 16px;
}

.delivery-pickup-form p {
    font-size: 12px;
    color: var(--dark-color-red);
    margin-bottom: 10px;
}

.form-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.form-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.form-group {
    display: flex;
    flex-direction: column;
}

.form-group.full-width {
    grid-column: span 2;
}

label {
    margin-bottom: 5px;
}

input {
    font-family: 'MyFont', sans-serif;
    text-align: left;
    padding: 10px;
    border: 2px solid var(--dark-color-background);
    border-radius: 2px;
    background-color: var(--dark-color-font);
}


.custom-time-picker {
    position: relative;
    width: 100%;
}

.custom-time-picker input {
    height: 40px;
}

.dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    color: var(--dark-color-background);
    background-color: var(--dark-color-font);
    border: 1px solid var(--dark-border-color);
    max-height: 150px;
    overflow-y: auto;
    width: 100%;
    z-index: 1000;
}

.dropdown li {
    padding: 5px 10px;
    cursor: pointer;
}

.dropdown li:hover {
    background-color: var(--dark-color-background);
    color: var(--dark-color-font);
}

.cart-summary {
    font-size: 16pt;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    margin-top: 40px;
    width: 70%;
    margin-left: auto;
    /* Dodane, aby wypchnąć element na prawą stronę */
}

.cart-summary button {
    margin-top: 40px;
    font-size: 16pt;
    padding: 10px 20px;
    background-color: var(--dark-color-background);
    border: 2px solid transparent;
    transition: background 0.3s, color 0.3s;
}

.cart-summary button:hover {
    background-color: var(--dark-color-font);
    color: var(--dark-color-background);
    border: 2px solid var(--dark-color-background);
}

.summary {

    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 10px;

    border-bottom: 2px solid var(--dark-color-background);
    padding-bottom: 10px;
}

.summary .price {
    width: 25%;
    font-size: 14pt;
    border-left: 2px solid var(--dark-color-background);
    padding-left: 15px;
    color: var(--dark-color-red);
}

.summary .sum {
    width: 50%;
}


.error-message {
    color: red !important;
    font-size: 14px !important;
}



.shop .success_message {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1100px;
    margin-top: 50px;
    /* Odstęp od góry */
}

.shop .success-content {
    flex: 1;
    text-align: left;
}

.shop .success-title {
    font-size: 24px;
    /* Zwiększenie rozmiaru tekstu */
    font-weight: bold;
    margin-bottom: 30%;
}

.shop .success-subtitle {
    font-size: 16pt;
    color: var(--dark-color-background);
    /* Zmiana koloru napisu */
    margin-bottom: 20px;
}

.shop .success-subtitle-1 {
    font-size: 10px;
    color: var(--dark-color-background);
    /* Zmiana koloru napisu */
    margin-bottom: 20px;
}

.shop .success-button {
    padding: 15px 30px;
    background-color: var(--dark-color-background);
    /* Ciemne tło przycisku */
    color: var(--dark-color-font);
    /* Kolor tekstu */
    border: 2px solid transparent;
    border-radius: 2px;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 14px;
    width: 70%;
    font-weight: bold;
    transition: background-color 0.3s;
}

.shop .success-button:hover {
    background-color: var(--dark-color-font);
    color: var(--dark-color-background);
    border: 2px solid var(--dark-color-background);
    transition: background 0.3s, color 0.3s;
    /* Zmiana koloru tła przycisku po najechaniu */
}

.shop .success-image img {
    max-width: 500px;
    /* Ustawienie szerokości obrazka */
    height: auto;
}


@media (max-width: 800px) {

    ::v-deep .v3dp__input_wrapper {
        font-size: 14px;
    }
    ::v-deep .v3dp__input_wrapper input{
        font-size: 10px;
    }

    .shop {
        padding: 20px;
        padding-top: 120px;
        display: flex;
        flex-direction: column;
        align-items: center;
        /* Wyśrodkowanie poziome */
        justify-content: center;
        /* Wyśrodkowanie pionowe */
        min-height: 100vh;
        /* Minimalna wysokość na 100% okna przeglądarki */
        box-sizing: border-box;
    }

    .form-grid {
        display: flex;
        flex-direction: column;
        /* Układ kolumnowy dla wąskich ekranów */
        gap: 20px;
        font-size: 10px;
    }

    .form-group {
        width: 100%;
        /* Ustawienie pełnej szerokości dla wszystkich pól */
    }

    .form-group.full-width {
        width: 100%;
        /* Pełna szerokość również dla pełnych pól */
    }

    .radio-group {
        display: flex;
        flex-direction: column;
        /* Układ w kolumnie */
        gap: 10px;
        /* Odstęp między opcjami */
    }

    .radio-option {
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
        font-size: 10px;
        /* Dopasowanie rozmiaru tekstu */
        user-select: none;
        min-height: 24px;
    }

    .radio-option input[type="radio"] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 16px;
        width: 16px;
    }

    input,
    textarea,
    .custom-time-picker input {
        width: 100%;
        /* Wszystkie pola na pełną szerokość */
        font-size: 10px;
        /* Mniejsza czcionka dla pól */
        box-sizing: border-box;
        /* Uwzględnienie paddingu w szerokości */
    }

    label {
        font-size: 10px;
        /* Mniejsza czcionka dla etykiet */
    }


    .custom-radio {
        display: inline-block;
        width: 16px;
        /* Rozmiar kwadratu */
        height: 16px;
        /* Rozmiar kwadratu */
        background-color: var(--dark-color-font);
        border: 2px solid var(--dark-color-background);
        margin-right: 10px;
        flex-shrink: 0;
        position: relative;
    }

    .radio-option input[type="radio"]:checked~.custom-radio {
        background-color: var(--dark-color-background);
    }

    .custom-radio::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 8px;
        height: 8px;
        background-color: var(--dark-color-font);
        transform: translate(-50%, -50%);
        opacity: 0;
    }

    .radio-option input[type="radio"]:checked~.custom-radio::after {
        opacity: 1;
        /* Widoczny punkt w środku po zaznaczeniu */
    }

    .checkbox-group {
        display: flex;
        flex-direction: column;
        /* Układ w kolumnie */
        gap: 10px;
    }

    .custom-checkbox {
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
        font-size: 10px;
        /* Rozmiar tekstu */
        user-select: none;
    }

    .custom-checkbox input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 16px;
        width: 16px;
    }

    .checkmark {
        display: inline-block;
        width: 16px;
        /* Rozmiar kwadratu */
        height: 16px;
        /* Rozmiar kwadratu */
        background-color: var(--dark-color-font);
        border: 2px solid var(--dark-color-background);
        margin-right: 10px;
    }

    .custom-checkbox input:checked~.checkmark {
        background-color: var(--dark-color-background);
    }

    .cart-summary {
        font-size: 12px;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        margin-top: 20px;
        width: 100%;
    }

    .summary .price {
        font-size: 12px;
    }

    .cart-summary button {
        margin-top: 20px;
        font-size: 14px;
        padding: 10px 20px;
    }
}
</style>