<template>
  <div :class="{ 'dark-mode': isDarkMode }" class="carousel" @wheel="handleScroll" @touchstart="handleTouchStart"
    @touchmove="handleTouchMove" @touchend="handleTouchEnd">
    <div class="carousel-inner" :style="{ transform: `translateX(-${slideStoreIndex * 100}%)` }">
      <div class="carousel-item" v-for="(slide, index) in slides" :key="index">
        <img :src="slide.image" :alt="slide.alt">
        <div class="overlay"></div>
        <div class="caption">
          <p class="title" v-if="translations.headers[slide.alt]">{{ translations.headers[slide.alt] + "\n\n" }}</p>
          <p>{{ translations.title[slide.alt][0] }}</p>
        </div>
      </div>
    </div>
    <div class="carousel-controls">
      <div class="carousel-control prev">
        <button class="carousel-control" @click="prevSlide">❮</button>
      </div>
      <div class="carousel-control next">
        <button class="carousel-control" @click="nextSlide">❯</button>
      </div>
    </div>
    <div class="carousel-indicators">
      <span v-for="(slide, index) in slides" :key="index" :class="{ active: index === slideStoreIndex }"
        @click="goToSlide(index)"></span>
    </div>
  </div>
</template>


<script>

import { useSlideStore } from '../../store/slideStore';
import { computed } from 'vue';
import translationsData from '@/assets/text_lang/translations.json';
import { useLanguageStore } from '@/theme';

export default {
  data() {
    return {
      slides: [
        { image: require('@/assets/images/offer/OFERTA-EVENTOWA-1.webp'), alt: 'image 1', darkMode: false },
        { image: require('@/assets/images/offer/OFERTA-EVENTOWA-2.webp'), alt: 'image 2', darkMode: false },
        { image: require('@/assets/images/offer/OFERTA-EVENTOWA-3.webp'), alt: 'image 3', darkMode: false },
        { image: require('@/assets/images/offer/OFERTA-EVENTOWA-4.webp'), alt: 'image 4', darkMode: false },
        { image: require('@/assets/images/offer/OFERTA-EVENTOWA-5.webp'), alt: 'image 5', darkMode: false },
        { image: require('@/assets/images/offer/OFERTA-EVENTOWA-6.webp'), alt: 'image 6', darkMode: false },

        // Dodaj więcej slajdów według potrzeb
      ]
    }
  },
  setup() {
    const slideStore = useSlideStore();
    const languageStore = useLanguageStore();

    const translations = computed(() => {
      return translationsData[languageStore.currentLanguage]["event_page"];
    });
    const slideStoreIndex = computed(() => slideStore.slideIndex);
    return {
      currentLanguage: languageStore.currentLanguage,
      translations,
      slideStoreIndex,
    };
  },
  computed: {
    isDarkMode() {
      return this.slides[this.slideStoreIndex].darkMode || false;
    },
  },
  methods: {
    handleTouchStart(event) {
      this.touchStartX = event.touches[0].clientX;
    },
    handleTouchMove(event) {
      this.touchEndX = event.touches[0].clientX;
    },
    handleTouchEnd() {
      const swipeThreshold = 50;
      if (this.touchStartX - this.touchEndX > swipeThreshold) {
        this.nextSlide();
      } else if (this.touchEndX - this.touchStartX > swipeThreshold) {
        this.prevSlide();
      }
      this.touchStartX = 0;
      this.touchEndX = 0;
    },

    nextSlide() {
      const slideStore = useSlideStore();
      if (this.slideStoreIndex == this.slides.length - 1) {
        slideStore.setSlideIndex(0);
        this.$router.push('/portfolio');
      } else {
        slideStore.setSlideIndex((this.slideStoreIndex + 1) % this.slides.length);
      }
    },
    prevSlide() {
      const slideStore = useSlideStore();
      if (this.slideStoreIndex == 0) {
        slideStore.setSlideIndex(3);
        this.$router.push('/about');
      } else {
        slideStore.setSlideIndex((this.slideStoreIndex - 1 + this.slides.length) % this.slides.length);
      }

    },
    goToSlide(index) {
      const slideStore = useSlideStore();
      slideStore.setSlideIndex(index);
    },
    handleScroll(event) {
      const currentTime = new Date().getTime();
      if (currentTime - this.lastScroll < 500) {
        return;
      }
      this.lastScroll = currentTime;
      if (event.deltaY > 0) {
        this.nextSlide();
      } else {
        this.prevSlide();
      }
    }
  }
}
</script>

<style scoped>
.title-text {
  white-space: pre-line;
  letter-spacing: 2px;
  line-height: 2;
}

.overlay {
  background: rgba(0, 0, 0, 0.5);
  /* Przezroczysta warstwa */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}


.carousel {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  letter-spacing: 5px;
}

.carousel-inner {
  display: flex;
  transition: transform 0.5s ease;
}

.carousel-item {
  min-width: 100%;
  height: 100vh;
  position: relative;
}

.carousel-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.caption {
  position: absolute;
  white-space: pre-line;
  margin-top: 20px;
  top: 50%;
  left: 45%;
  transform: translate(-50%, -50%);
  text-align: left;
  width: 70%;
}

.dark-mode .caption {
  color: var(--dark-color-background);
}

.caption p {
  font-size: 16pt;
  line-height: 20pt;
  margin: 0;
  width: 80%;
}

.caption p.title {
  font-size: 20pt;
  letter-spacing: 4px;
  line-height: 22pt;
}

.carousel-controls {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  pointer-events: none;
}

.carousel-control {
  background: none;
  border: none;
  font-size: 3rem;
  /* Adjust font size as needed */
  color: var(--dark-color-font);
  cursor: pointer;
  pointer-events: all;
}

.dark-mode .carousel-control {
  color: var(--dark-color-background);
}

.carousel-control.prev {
  width: 50%;
}

.carousel-control.prev button {
  display: flex;
  justify-content: flex;
  margin-left: 15px;
  width: 40px;
}

.carousel-control.next {
  display: flex;
  justify-content: flex-end;
  width: 50%;

}

.carousel-control.next button {
  margin-right: 15px;
  width: 40px;
}


.carousel-indicators {

  position: absolute;
  bottom: 30px;
  margin-bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}


.carousel-indicators span {
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: 3px solid var(--dark-color-font);
  margin: 0 5px;
  cursor: pointer;
}

.dark-mode .carousel-indicators span {
  border: 4px solid var(--dark-color-background);
}

.carousel-indicators span.active {
  background-color: var(--dark-color-font);
}

.dark-mode .carousel-indicators span.active {
  background-color: var(--dark-color-background);
}

.hero-button {
  letter-spacing: 3px;
  text-align: center;
  font-size: 18px;
  margin-top: 30px;
  width: 40%;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--dark-color-font);
  color: var(--dark-color-background);
  padding: 10px 20px;
  text-decoration: none;
  transition: background 0.3s, color 0.3s;
  border: 2px solid transparent;
}

.dark-mode .hero-button {
  background: var(--dark-color-background);
  color: var(--dark-color-font);
}

.hero-button:hover {
  background: var(--dark-color-background);
  color: var(--dark-color-font);
  border: 2px solid var(--dark-color-font);
}

.dark-mode .hero-button:hover {
  background: var(--dark-color-font);
  color: var(--dark-color-background);
  border: 2px solid var(--dark-color-background);
}

@media (max-width: 1300px) {
  .caption p {
    font-size: 12px;
    line-height: 14pt;
  }

  .caption p.title {
    font-size: 18px;
    line-height: 18pt;
  }

  .hero-button {
    font-size: 15px;
  }
}

@media (max-width: 822px) {


  .carousel-controls {
    display: none;
    /* hidden */
    visibility: hidden;
  }

  .caption {
    top: 50%;
    left: 50%;
    letter-spacing: 3px;
    line-height: 16pt;
  }

  .caption p.title {
    font-size: 13px;
    line-height: 16pt;
  }

  .caption p {
    font-size: 10px;
    line-height: 16pt;
    letter-spacing: 2px;
    width: 100%;
  }

  .hero-button {
    font-size: 14px;
    width: 50%;
  }

  .carousel-control {
    font-size: 1.5rem;
  }

  .carousel-control.prev button {
    margin-left: 10px;
  }


  .carousel-control.next button {
    margin-right: 10px;
  }

  .carousel-indicators {
    bottom: 15px;
    margin-bottom: 15px;
  }
  .carousel-indicators span {
    width: 15px;
    height: 15px;
    margin: 0 3px;
  }

}


@media (max-height: 900px) and (min-width: 1301px) {
  .caption p {
    width: 65%;
    font-size: 16px;
    line-height: 16pt;
    letter-spacing: 2px;
  }

  .caption p.title {
    font-size: 20px;
    line-height: 18pt;
  }
}

</style>