<template>
  <div class="navbar-container">
    <nav class="navbar" :class="{ 'dark-mode': isDarkMode }">
      <div class="navbar-logo">
        <router-link to="/">
          <img :src="logoSrc" alt="Logo" />
        </router-link>
      </div>
      <p class="title-rent">{{ translations.title }}</p>

      <div class="navbar-toggle" @click="toggleMenu" @mouseover="handleMouseOver" @mouseleave="handleMouseLeave">
        <span></span>
        <span></span>
        <span></span>
      </div>


      <ul class="navbar-list" :class="{ active: isOpen || isHovered }">

        <li v-if="!isMobile" class="search-input">
          <input type="text" v-model="searchQuery" @input="updateSearchQuery" :placeholder="translations.search_button"
            @keydown.enter="submitSearch"  />
          <button class="search-button" @click="submitSearch">
            <i class="pi pi-search"></i>
          </button>
        </li>

        <li v-for="(item, index) in translations.menu_items" :key="index" @mouseover="handleSubmenuMouseOver(index)"
          @mouseleave="handleSubmenuMouseLeave">
          <router-link :to="item.link" exact-active-class="active"
            v-if="!isMobile || (isMobile && item.link.startsWith('/cart'))">
            <template v-if="item.icon">
              <img :src="require('@/assets/icons/cart.webp')" alt="Logo" />
              <span v-if="cartItemCount > 0" class="cart-badge">{{ cartItemCount }}</span>
            </template>
            <template v-else>
              {{ item.name }}
            </template>
          </router-link>
        </li>
      </ul>
      <div v-if="!isMobile" class="vertical-line"></div>
      <div v-if="!isMobile" class="language-select">
        <ul class="nav-menu">
          <li>
            <button @click="changeLanguage('pl')" :class="{ 'active': currentLanguage === 'pl' }"
              exact-active-class="active">POL</button>
            <button @click="changeLanguage('en')" :class="{ 'active': currentLanguage === 'en' }"
              exact-active-class="active">ENG</button>
          </li>
        </ul>
      </div>
    </nav>

    <nav v-if="!isMobile" class="navbar-drugi" :class="{ 'dark-mode': isDarkMode }">

      <ul class="navbar-list" :class="{ active: isOpen || isHovered }" @mouseover="handleMouseOver"
        @mouseleave="handleMouseLeave">
        <li v-for="(item, index) in categoryNames" :key="index" @mouseover="handleSubmenuMouseOver(index)"
          @mouseleave="handleSubmenuMouseLeave">
          <button @click="changeCategory(Object.keys(item)[0])"
            :class="{ 'active': currentCategory === Object.keys(item)[0] && isActive('/rent') }"
            exact-active-class="active">
            {{ Object.values(item)[0] }}
          </button>
          <ul v-if="item.subItems" class="dropdown-menu" :class="{ active: isSubmenuOpen === index }">
            <li v-for="(subItem, subIndex) in item.subItems" :key="subIndex">
              <button @click="changeCategory(Object.keys(item)[0])">{{ subItem.name }}</button>
            </li>
          </ul>
        </li>
      </ul>
    </nav>


    <nav v-if="isMobile" class="mobile-menu" @mouseover="handleMouseOver" @mouseleave="handleMouseLeave">
      <ul class="mobile-menu-list" :class="{ active: isMobileMenuOpen }">
        <input type="text" v-model="searchQuery" @input="updateSearchQuery" :placeholder="translations.search_button"
          @keydown.enter="submitSearch" />
        <li v-for="(item, index) in translations.menu_items" :key="index">
          <button @click="pricePage" :class="{ active: router.currentRoute.value.path.startsWith('/price') }"
            v-if="!item.link.startsWith('/cart')">
            {{ item.name }}
          </button>
        </li>

        <li v-for="(item, index) in categoryNames" :key="index">
          <button @click="changeCategory(Object.keys(item)[0])"
            :class="{ active: currentCategory === Object.keys(item)[0] && router.currentRoute.value.path.startsWith('/rent') }">
            {{ Object.values(item)[0] }}
          </button>
        </li>
        <li>
          <button @click="changeLanguage('pl')" :class="{ 'active': currentLanguage === 'pl' }"
            exact-active-class="active">POL</button>
          <button @click="changeLanguage('en')" :class="{ 'active': currentLanguage === 'en' }"
            exact-active-class="active">ENG</button>
        </li>
      </ul>
    </nav>


  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import 'primeicons/primeicons.css';
import translationsData from '@/assets/text_lang/translations.json';
import { useCategoryStore, useLanguageStore, useSearchStore } from '@/theme';
import { useCartStore } from '../../store/cart';
export default {
  data() {
    return {
      isOpen: false,
      isHovered: false,
      isSubmenuOpen: null,
      hoverTimeout: null,
      isDarkMode: true,
      selectedCategory: 'Home',
      isSearchOpen: false,
    };
  },
  computed: {
    logoSrc() {
      return require('@/assets/icons/Cocktail-Service-logo-RGB-WHITE.webp');
    },
  },
  setup() {
    const router = useRouter();
    const languageStore = useLanguageStore();
    const categoryStore = useCategoryStore();
    const searchStore = useSearchStore();
    const cartStore = useCartStore();
    const isMobile = ref(window.innerWidth <= 1400);
    const isMobileMenuOpen = ref(false);
    const translations = computed(() => {
      return translationsData[languageStore.currentLanguage]["rent"];
    });
    const currentLanguage = computed(() => languageStore.currentLanguage);
    const changeLanguage = (lang) => {
      languageStore.setLanguage(lang);
      if (isMobile.value) {
        isMobileMenuOpen.value = false;
      }
    };

    const cartItemCount = computed(() => {
      return cartStore.items.length; // Załóżmy, że masz `cartItems` w swoim Store
    });

    const categoryNames = computed(() => {
      return Object.entries(translations.value.menu_category).map(([key, category]) => ({
        [key]: category.name
      }));
    });
    const currentCategory = computed(() => categoryStore.currentCategory);
    const changeCategory = (category) => {
      searchStore.searchQuery = '';
      if (category === 'event') {
        router.push('/event');
        setTimeout(() => {
          categoryStore.setCategory('home');
        }, 0)
      } else {
        categoryStore.setCategory(category);
        if (router.currentRoute.value.path !== '/rent') {
          router.push('/rent');
        }
      }
      if (isMobile.value) {
        isMobileMenuOpen.value = false;
      }
    };


    const submitSearch = () => {
      console.log('Wyszukiwanie:', searchStore.searchQuery); // Wyświetl wartość globalnego Store
    };

    // Aktualizowanie stanu wyszukiwania w store
    const updateSearchQuery = (event) => {
      searchStore.setSearchQuery(event.target.value); // Ustawia wartość w globalnym Store
    };

    return {
      router,
      cartItemCount,
      isMobile,
      categoryNames,
      isMobileMenuOpen,
      currentLanguage,
      changeCategory,
      currentCategory,
      changeLanguage,
      languageStore,
      translations,
      searchQuery: searchStore.searchQuery,
      submitSearch, // Upewnij się, że jest zwrócone
      updateSearchQuery,

    }
  },
  methods: {
    isActive(path) {
      return path.startsWith('/rent');
    },
    toggleMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
    },
    handleMouseOver() {
      clearTimeout(this.hoverTimeout);
      this.isMobileMenuOpen = true;
    },
    pricePage() {
      this.isMobileMenuOpen = false;
      this.$router.push('/price');
    },
    handleMouseLeave() {
      this.hoverTimeout = setTimeout(() => {
        this.isMobileMenuOpen = false;
      }, 300);
    },
    handleSubmenuMouseOver(index) {
      this.isSubmenuOpen = index;
    },
    handleSubmenuMouseLeave() {
      this.isSubmenuOpen = null;
    },
    toggleDarkMode() {
      this.isDarkMode = !this.isDarkMode;
      document.body.classList.toggle('dark-mode', this.isDarkMode);
      localStorage.setItem('isDarkMode', this.isDarkMode);
    },
    checkIsMobile() {
      this.isMobile = window.innerWidth <= 1100;
    },
    handleClickOutside(event) {
      if (!this.isMobile) {
        return;
      }
      const menu = this.$el.querySelector('.mobile-menu-list'); // Znajdź rozwijane menu
      const toggleButton = this.$el.querySelector('.navbar-toggle'); // Znajdź przycisk toggle

      // Sprawdź, czy kliknięto poza menu i przyciskiem toggle
      if (this.isMobileMenuOpen && !menu.contains(event.target) && !toggleButton.contains(event.target)) {
        this.isMobileMenuOpen = false;
      }
    },
  },
  mounted() {
    window.addEventListener('resize', this.checkIsMobile);
    this.checkIsMobile();
    this.isMobileMenuOpen = false;
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkIsMobile);
    document.removeEventListener('click', this.handleClickOutside);
  },
};
</script>

<style scoped>
.navbar-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* max-height: 18%; */
  position: fixed;
  z-index: 1000;
  letter-spacing: 3px;
}

.navbar {
  /* letter-spacing: 3px; */
  font-size: 14px;
  /* position: fixed;
  top: 0;
  left: 0; */
  width: 100%;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: var(--light-color-background);
  transition: background-color 0.3s ease, color 0.3s ease;
  height: 100px;
}

.navbar-drugi {
  /* position: fixed; */
  font-size: 11px;
  /* top: 13%;
  left: 0; */
  width: 100%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: var(--dark-color-background);
  transition: background-color 0.3s ease, color 0.3s ease;
  height: 70px;
  border-top: 2px solid var(--dark-color-font);
}

.navbar .title-rent {
  margin-left: 180px;
  font-size: 15pt;
  line-height: 18pt;
}

.navbar-drugi button {
  all: unset;
}

.search-input {
  margin-top: 3px;
  margin-right: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  /* Ustawia element poziomo na środku */
  align-items: center;
  transition: max-width 0.5s ease, opacity 0.5s ease;
}

.search-input input {
  text-transform: uppercase;
  padding: 5px 10px;
  color: var(--dark-color-background);
  width: 230px;
  /* Dostosuj szerokość pola wyszukiwania */
  height: 40px;
}

.search-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 0px;
  background: none;
  border: none;
  color: var(--dark-color-font);
  border: 2px solid transparent;
  font-size: 16px;
  cursor: pointer;
  position: relative;
  /* Dodanie pozycji, aby wyrównanie ikony było precyzyjne */
}

.search-button i {
  padding: 0;
  font-size: 20px;
  /* Dostosowanie rozmiaru ikony */
  line-height: 1;
  /* Usunięcie odstępów wewnętrznych */
}

.search-button:hover {
  border: 2px solid var(--dark-color-font);
  background-color: var(--dark-color-background-hover);
  border-radius: 2pt;
}



/* .search-input input:active {
  co
} */

/* .navbar-list.active .search-input {
  opacity: 1;
  max-width: 200px;
  margin-right: 20px;
} */

.navbar.dark-mode {
  background-color: var(--dark-color-background);
}

.navbar-logo {
  position: absolute;
  left: 10px;
}

.navbar-logo router-link {
  display: inline-block;
}

.navbar-logo img {
  height: 120px;
  width: auto;
  max-width: 100%;
}

.dark-mode-logo img {
  height: 20px;
  width: auto;
  max-width: 100%;
}

.navbar-list li a {
  width: auto;
  height: 30px;
  border: 1px solid transparent;
  display: flex !important;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.navbar-list img {
  width: 30px;
  height: 30px;
  border: 1px solid transparent;
  /* filter: invert(94%) sepia(5%) saturate(402%) hue-rotate(317deg) brightness(105%) contrast(85%); */
}

.dark-mode-logo img {
  height: 20px;
  width: auto;
  max-width: 100%;
}


.nav-menu li button {
  display: block;
  font-size: 14px;
  color: var(--light-color-font);
  background: none;
  padding: 20px 20px;
  border: none;
  margin: 0px;
  text-decoration: none;
  transition: color 0.3s ease, background-color 0.3s ease, border-bottom 0.3s ease;
  border-bottom: 2px solid transparent;
}

.dark-mode .nav-menu li button {
  color: var(--dark-color-font);
}

.nav-menu li button:hover {
  border-bottom: 2px solid var(--dark-color-font)
}

.nav-menu li button.active {
  border-bottom: 2px solid var(--dark-color-font);
}

.navbar-list {
  display: flex;
  list-style: none;
  justify-content: flex-end;
  flex-grow: 1;
  margin: 0;
  margin-right: 25px;
  padding: 0;
}

.navbar-drugi .navbar-list {
  justify-content: center;
}


.navbar-list.active {
  max-height: 200px;
  opacity: 1;
  visibility: visible;
}

.navbar-list li {
  position: relative;
}

.navbar-list button,
.navbar-list li a {
  display: block;
  color: var(--light-color-font);
  padding: 20px 40px;
  text-decoration: none;
  transition: color 0.3s ease, background-color 0.3s ease, border-bottom 0.3s ease;
  border-bottom: 2px solid transparent;
}

.navbar-list button,
.dark-mode .navbar-list li a {
  color: var(--dark-color-font);
}

.navbar-list button:hover,
.navbar-list li a:hover {
  border-bottom: 2px solid var(--dark-color-font);
}

.navbar-list button .active,
.navbar-list li .active {
  border-bottom: 2px solid var(--dark-color-font);
  color: var(--dark-color-font);
}


.navbar-toggle {
  position: absolute;
  right: 40px;
  display: none;
  flex-direction: column;
  cursor: pointer;
  background: none;
  border: none;
}

.navbar-toggle span {
  height: 3px;
  width: 25px;
  background-color: var(--dark-color-background);
  margin: 4px 0;
  border-radius: 1px;
}

.dark-mode .navbar-toggle span {
  background-color: var(--dark-color-font);
}

.navbar button {
  cursor: pointer;
}

.dark-mode-toggle {
  background-color: transparent;
  border: 1px solid #0C1826;
  color: #0C1826;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
}

.dark-mode .dark-mode-toggle {
  border-color: var(--dark-color-font);
  color: var(--dark-color-font);
}

.dark-mode-toggle:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.nav-menu {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.language-select {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  width: auto;
  color: var(--dark-color-font);
  margin-right: 5px;
}

.language-select ul {
  margin: auto;
  margin-left: 25px;
  margin-right: 25px;
}

.language-select li {
  display: flex;
}

.language-select li a {
  display: block;
  color: var(--light-color-font);
  padding: 20px 20px;
  text-decoration: none;
  transition: color 0.3s ease;
  border-bottom: 2px solid transparent;
}

.language-select a:hover {
  border-bottom: 2px solid var(--dark-color-font);
}

.vertical-line {
  width: 1px;
  height: 130px;
  background-color: var(--dark-color-font);
}

.dropdown-menu {
  display: none;
  position: absolute;
  cursor: pointer;
  top: 100%;
  left: 0;
  background-color: var(--light-color-background);
  z-index: 1000;
  min-width: 180px;
  padding: 10px 0;
  list-style: none;
}

.dropdown-menu li {
  padding: 8px 16px;
  width: 300px;

}

.dropdown-menu li a {
  color: var(--dark-color-font);
  text-decoration: none;
  font-size: 10px;
}

.navbar-list li .dropdown-menu.active {
  display: block;
  border: none;
}

.dark-mode .dropdown-menu {
  background-color: var(--dark-color-background);
}






.navbar-drugi {
  display: flex;
  justify-content: center;
  background-color: var(--dark-color-background);
  padding: 10px;
  border-top: 2px solid var(--dark-color-font);
}

.navbar-drugi .dropdown-menu {
  display: none;
  position: absolute;
  background-color: var(--light-color-background);
  list-style: none;
  padding: 10px;
  margin-top: 10px;
}

.navbar-drugi .dropdown-menu.active {
  display: block;
}

.mobile-menu {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  padding: 0px;
  background-color: var(--dark-color-background);
}


.mobile-menu-list {
  display: none;
  list-style: none;
  margin: 0;
  padding: 0;
}

.mobile-menu-list input {
  text-transform: uppercase;
  margin: 0 10px;
  color: var(--dark-color-background);
  /* Dostosuj szerokość pola wyszukiwania */
  height: 40px;
}


.mobile-menu-list.active {
  display: block;
}

.mobile-menu-list li {
  margin: 0px 0;
  transition: color 0.3s ease, background-color 0.3s ease, border-bottom 0.3s ease;
}

.mobile-menu-list li button {
  white-space: pre;
  font-size: 10px;
  color: var(--dark-color-font);
  padding: 14px;
  text-decoration: none;
  margin: 0;
  text-align: left;
  width: 100%;
  background: none;
  border: none;
  border-bottom: 2px solid transparent;
  transition: color 0.3s ease, background-color 0.3s ease, border-bottom 0.3s ease;
}

.mobile-menu-list li button:hover {
  background-color: #b1afaf;
  color: #000;
  border-bottom: 2px solid var(--dark-color-font)
}

.mobile-menu-list li button.active {
  border-bottom: 2px solid var(--dark-color-font);
}



.cart-icon-container {
  position: relative;
  display: inline-block;
}

.cart-badge {
  position: absolute;
  top: 10px;
  right: 10px;
  transform: translate(50%, -50%); /* Wycentrowanie badge w obu osiach */
  background-color: var(--dark-color-red);
  color: var(--dark-color-font);
  font-size: 12px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 0; /* Ważne dla prawidłowego wyrównania liczby */
  letter-spacing: 1px;
}




@media (max-width: 1400px) {
  .title-rent {
    visibility: hidden;
    width: 0;
  }
}

@media (min-width: 1101px) {
  .mobile-menu {
    display: none;
  }
}

@media (max-width: 1100px) {


  .navbar {
    height: 65px;
    font-size: 10px;
    width: 100%;
  }

  .navbar-logo img {
    height: 100px;
    width: auto;
    max-width: 100%;
  }

  .navbar-toggle {
    padding: 20px 20px;
  }

  .navbar-toggle {
    display: flex;
    right: 85px;
  }


  .language-select,
  .vertical-line {
    visibility: hidden;
  }

  .navbar-list li a {
    padding: 10px 20px;
  }

  .navbar-list img {
    width: 25px;
    height: 25px;
    border: 1px solid transparent;
    /* filter: invert(94%) sepia(5%) saturate(402%) hue-rotate(317deg) brightness(105%) contrast(85%); */
  }

}

@media (min-width: 1111px) {
  .navbar-drugi .navbar-list {
    max-height: none;
    opacity: 1;
    visibility: visible;
  }
}
</style>
