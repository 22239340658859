<template>
  <div :class="{ 'dark-mode': isDarkMode }" class="carousel" @wheel="handleScroll" @touchstart="handleTouchStart" @touchmove="handleTouchMove" @touchend="handleTouchEnd">
    <div class="carousel-inner" :style="{ transform: `translateX(-${currentSlide * 100}%)` }">
      <div class="carousel-item" v-for="(slide, index) in slides" :key="index">
        <img :src="slide.image" :alt="slide.alt">
        <div class="overlay"></div>
        <div class="caption">
          <p v-if="translations.image_carousel[slide.alt]?.title">
            {{ translations.image_carousel[slide.alt].title }}
          </p>
          <p class="desc" v-if="!translations.image_carousel[slide.alt]?.description">
            {{ translations.image_carousel[slide.alt]?.desc }}
          </p>
          <button v-if="translations.image_carousel[slide.alt]?.button" @click="changeCategory(slide.link)" class="hero-button">
            {{ translations.image_carousel[slide.alt].button }}
          </button>
        </div>
      </div>
    </div>
    <div class="carousel-controls">
      <div class="carousel-control prev">
        <button class="carousel-control" @click="prevSlide">❮</button>
      </div>
      <div class="carousel-control next">
        <button class="carousel-control" @click="nextSlide">❯</button>
      </div>
    </div>
    <div class="carousel-indicators">
      <span v-for="(slide, index) in slides" :key="index" :class="{ active: index === currentSlide }"
        @click="goToSlide(index)"></span>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useCategoryStore } from '@/theme';
import { useSlideStore } from '../../store/slideStore';
import translationsData from '@/assets/text_lang/translations.json';
import { useLanguageStore } from '@/theme';

export default {
  data() {
    return {
      currentSlide: 0,
      slides: [
        { image: require('@/assets/images/offer/CS-(101).webp'), alt: 'image_3', darkMode: false },
        { image: require('@/assets/images/offer/DSC08900.webp'), alt: 'image_1', darkMode: true, link: 'glass' },
        { image: require('@/assets/images/offer/CS-(134).webp'), alt: 'image_2', darkMode: false, link: 'bars' },
      ]
    }
  },
  setup() {
    const languageStore = useLanguageStore();

    const categoryStore = useCategoryStore();
    const changeCategory = (category) => {
      categoryStore.setCategory(category);
      console.log(category);  
    };

    const translations = computed(() => {
      return translationsData[languageStore.currentLanguage]["rent"];
    });
    return {
      currentLanguage: languageStore.currentLanguage,
      translations,
      changeCategory,
    };
  },
  computed: {
    isDarkMode() {
      if (!this.slides || !this.slides[this.currentSlide]) {
        return false;
      }
      return this.slides[this.currentSlide].darkMode ?? false;
    }
  },
  methods: {
    handleTouchStart(event) {
      this.touchStartX = event.touches[0].clientX;
    },
    handleTouchMove(event) {
      this.touchEndX = event.touches[0].clientX;
    },
    handleTouchEnd() {
      const swipeThreshold = 50;
      if (this.touchStartX - this.touchEndX > swipeThreshold) {
        this.nextSlide();
      } else if (this.touchEndX - this.touchStartX > swipeThreshold) {
        this.prevSlide();
      }
      this.touchStartX = 0;
      this.touchEndX = 0;
    },
    nextSlide() {
      if (this.currentSlide == 2) {
        const slideStore = useSlideStore();
        slideStore.setSlideIndex(0);
      } else {
        this.currentSlide = (this.currentSlide + 1) % this.slides.length;
      }
    },
    prevSlide() {
      if (this.currentSlide == 0) {
        const slideStore = useSlideStore();
        slideStore.setSlideIndex(4);
      } else {
        this.currentSlide = (this.currentSlide - 1 + this.slides.length) % this.slides.length;
      }
    },
    goToSlide(index) {
      this.currentSlide = index;
    }
  }
}
</script>

<style scoped>
.overlay {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dark-mode .overlay {
  background: rgba(255, 255, 255, 0.1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  letter-spacing: 5px;
}

.carousel-inner {
  display: flex;
  transition: transform 0.5s ease;
}

.carousel-item {
  min-width: 100%;
  height: 100vh;
  position: relative;
}

.carousel-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.caption {
  white-space: pre-line;
  position: absolute;
  top: 50%;
  left: 45%;
  transform: translate(-50%, -50%);
  text-align: left;
  width: 60%;

}

.dark-mode .caption {
  color: var(--dark-color-background);
}

.caption p {
  font-size: 22pt;
  line-height: 22pt;
  margin: 0;
  color: var(--dark-color-font);
}

.dark-mode .caption p {
  color: var(--dark-color-background);
}

.caption .desc {
  font-size: 12pt;
  line-height: 18pt;
}


.carousel-controls {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  pointer-events: none;
}


.carousel-control {
  background: none;
  border: none;
  font-size: 3rem;
  /* Adjust font size as needed */
  color: var(--dark-color-font);
  cursor: pointer;
  pointer-events: all;
}

.dark-mode .carousel-control {
  color: var(--dark-color-background);
}

.carousel-control.prev {
  width: 50%;
}

.carousel-control.prev button {
  display: flex;
  justify-content: flex;
  margin-left: 15px;
  width: 40px;
}

.carousel-control.next {
  display: flex;
  justify-content: flex-end;
  width: 50%;

}

.carousel-control.next button {
  margin-right: 15px;
  width: 40px;
}


.carousel-indicators {

  position: absolute;
  bottom: 30px;
  margin-bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}


.carousel-indicators span {
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: 3px solid var(--dark-color-font);
  margin: 0 5px;
  cursor: pointer;
}

.dark-mode .carousel-indicators span {
  border: 4px solid var(--dark-color-background);
}

.carousel-indicators span.active {
  background-color: var(--dark-color-font);
}

.dark-mode .carousel-indicators span.active {
  background-color: var(--dark-color-background);
}

.hero-button {
  letter-spacing: 3px;
  text-align: center;
  font-size: 18pt;
  margin-top: 30px;
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--dark-color-font);
  color: var(--dark-color-background);
  padding: 10px 20px;
  text-decoration: none;
  transition: background 0.3s, color 0.3s;
  border: 2px solid transparent;
}

.dark-mode .hero-button {
  background: var(--dark-color-background);
  color: var(--dark-color-font);
}

.hero-button:hover {
  background: var(--dark-color-background);
  color: var(--dark-color-font);
  border: 2px solid var(--dark-color-font);
}

.dark-mode .hero-button:hover {
  background: var(--dark-color-font);
  color: var(--dark-color-background);
  border: 2px solid var(--dark-color-background);
}

@media (max-width: 1300px) {
  .caption p {
    font-size: 18px;
  }

  .hero-button {
    font-size: 15px;
  }
}

@media (max-width: 822px) {


  .carousel-controls {
    display: none;
    /* hidden */
    visibility: hidden;
  }

  .caption {
    top: 50%;
    left: 55%;
    width: 70%;
  }

  .caption p {
    font-size: 14px;
    line-height: 16pt;
    letter-spacing: 2px;
  }

  .caption .desc {
    font-size: 12px;
    line-height: 14pt;
  }


  .hero-button {
    height: 20px;
    font-size: 14px;
    width: 60%;
  }

  .carousel-control {
    font-size: 1.5rem;
  }

  .carousel-control.prev button {
    margin-left: 10px;
  }


  .carousel-control.next button {
    margin-right: 10px;
  }

  .carousel-indicators {
    bottom: 15px;
    margin-bottom: 15px;
  }

  .carousel-indicators span {
    width: 15px;
    height: 15px;
    margin: 0 3px;
  }
}
</style>