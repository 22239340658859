<template>
  <div class="contact-page">
    <div class="main-column">
      <p class="title">{{ translations.title }}</p>
      <div class="block">
        <div class="column left-column">
          <div class="logos">
            <a href="https://www.facebook.com/profile.php?id=61559974028659" target="_blank">
              <img :src="logofb" alt="Logo facebook" />
            </a>
            <a href="https://www.instagram.com/cocktailservice.pl/" target="_blank">
              <img :src="logoinst" alt="Logo instagram" />
            </a>
          </div>
          <!-- <a href="mailto:biuro@cocktailservice.pl" class="p-email">biuro@cocktailservice.pl</a> -->
          <a href="tel:+48664994155" class="contact-info">+48 664 994 155</a>
        </div>
        <div class="vertical-line"></div>
        <div class="column right-column">
          <!-- <p>{{translations.managers}}</p>
          <div class="manager">
            <p>MICHAŁ BARAN</p>
            <a href="tel:+48664994155" class="contact-info">+48 664 994 155</a><br>
            <a href="mailto:michal.baran@cocktailservice.pl" class="p-email">MICHAL.BARAN@COCKTAILSERVICE.PL</a>
          </div>
          <div class="manager">
            <p>KRZYSZTOF BRZOSTOWSKI</p>
            <a href="tel:+48795776733" class="contact-info">+48 795 776 733</a><br>
            <a href="mailto:krzysztof.brzostowski@cocktailservice.pl"
              class="p-email">KRZYSZTOF.BRZOSTOWSKI@COCKTAILSERVICE.PL</a>
          </div> -->
          <div class="logos">
            <p class="title-text">{{ translations.text_from }}</p>
          </div>
          <a href="mailto:biuro@cocktailservice.pl" class="contact-info">biuro@cocktailservice.pl</a>
        </div>
      </div>
      <!-- <div class="block">
        <div class="column left-column">
          <div class="logos">
            <p class="title-text" >{{translations.text_from}}</p>
          </div>
        </div>
        <div class="vertical-line"></div>
        <div class="column right-column">
          <form @submit.prevent="handleSubmit">
            <div class="form-group">
              <select id="destination" placeholder="" v-model="destination" required>
                <option value="" disabled>{{translations.form.address}}</option>
                <option value="michal.baran@cocktailservice.pl">MICHAL.BARAN@COCKTAILSERVICE.PL</option>
                <option value="krzysztof.brzostowski@cocktailservice.pl">KRZYSZTOF.BRZOSTOWSKI@COCKTAILSERVICE.PL</option>
                <option value="biuro@cocktailservice.pl">BIURO@COCKTAILSERVICE.PL</option>
              </select>
            </div>
            <div class="form-group">
              <input type="email" id="email" v-model="email" :placeholder=translations.form.your_address required />
            </div>
            <div class="form-group">
              <input type="text" id="subject" v-model="subject" :placeholder=translations.form.subject required />
            </div>
            <div class="form-group">
              <textarea id="message" rows="5" v-model="message" :placeholder=translations.form.message required></textarea>
            </div>
            <button class="submit-button" type="submit">{{translations.form.button}}</button>
          </form>
          <p v-if="successMessage" class="success-message">{{ successMessage }}</p>
        </div>
      </div> -->
      <div class="block">
        <div class="column left-column">
          <p class="title-text">{{ translations.title_address }}</p>
          <p class="title-text">
            COCKTAIL SERVICE SP. Z O.O.<br>
            Jagiellońska 82C Bud. 109<br>
            03-301, Warszawa
          </p>
        </div>
        <div class="vertical-line"></div>
        <div class="column right-column">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1220.713211224175!2d21.01808438636169!3d52.27195999396307!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471ecdd476ff71c7%3A0x9085d9431460e4d5!2sCocktail%20Service%20sp.%20z%20o.o.!5e0!3m2!1spl!2spl!4v1717150660342!5m2!1spl!2spl"
            allowfullscreen="" loading="lazy"></iframe>
        </div>
      </div>
    </div>

  </div>
  <AppFooter />
</template>

<script>

import { computed } from 'vue';
import translationsData from '@/assets/text_lang/translations.json';
import { useLanguageStore } from '@/theme';

import AppFooter from './FootBar.vue';

import axios from 'axios';

export default {
  name: 'ContactPage',
  components: {
    AppFooter
  },
  data() {
    return {
      subject: '',
      email: '',
      message: '',
      successMessage: '',
      destination: '',
      logofb: require('@/assets/images/contact/fb.webp'),
      logoinst: require('@/assets/images/contact/instagram.webp')
    };
  },
  setup() {
    const languageStore = useLanguageStore();

    const translations = computed(() => {
      return translationsData[languageStore.currentLanguage]["contact_page"];
    });
    return {
      currentLanguage: languageStore.currentLanguage,
      translations,
    };
  },
  methods: {
    async handleSubmit() {
      const payload = {
        toEmail: this.destination,
        fromEmail: this.email,
        subject: this.subject,
        message: this.message,
      };

      try {
        await axios.post('https://l4887ivvna.execute-api.eu-central-1.amazonaws.com/dev/email', payload);
        this.successMessage = 'Dziękujemy za wiadomość. Skontaktujemy się z Tobą wkrótce.';
        this.subject = '';
        this.email = '';
        this.message = '';
        this.destination = '';
      } catch (error) {
        console.error('Error sending email:', error);
      }
    }
  }
};
</script>

<style scoped>
.contact-page {

  font-size: 15pt;
  line-height: 30pt;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 200px;
  box-sizing: border-box;
  background-color: var(--dark-color-font);
  color: var(--dark-color-background);
  margin: 0 auto;
  height: 100%;
}

/* .AppFooter {
    width: 100%;
    position: relative;
    margin-top: auto;
} */

.main-column {
  max-width: 1300px;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
}

.title {
  color: var(--dark-color-background);
  margin: 20px 20px;
  font-size: 20pt;
}


.block {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 20px 0px;
  margin: 20px 0px;
  border-bottom: 1px solid var(--dark-color-background);
  height: 100%;

  /* Sprawdzamy, czy wysokość jest automatyczna */
}


.column {
  padding: 10px;
  margin: 10px;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.left-column {
  margin-right: 30px;
}

.left-column a {
  margin-bottom: 20px;
}
.right-column .contact-info
.left-column .contact-info {
  height: 10%;
  display: flex;
  flex-direction: column-reverse;
}

.logos {
  display: flex;
  height: 50%;
  gap: 10px;
  /* Dostosuj odstęp między logami */
}



.logos p {
  /* margin-bottom: 300px; */
  font-size: 12px;
}
.right-column  .logos .title-text{
  width: 60%;
  height: 99px;
  line-height: 16pt;
}

.text-iframe {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  /* Dostosuj odstęp między logami */
}

.text-iframe p {
  font-size: 12px;
}


.logos img {
  width: 50px;
  /* Dostosuj rozmiar loga według potrzeb */
  height: 50px;
  margin-bottom: 20px;
  object-fit: contain;
}

.vertical-line {
  width: 1px;
  height: auto;
  /* Dostosowanie wysokości do rodzica */
  background-color: var(--dark-color-background);
  padding: 0;
  margin: 40px 0px 30px 0px;
  /* Upewnienie się, że nie ma marginesów */
}

.p-email {
  font-size: 12px;
}

.contact-info {
  font-size: 12px;
  margin-bottom: 10px;
}

a {
  padding: 10px 0px;
  text-decoration: none;
  color: var(--dark-color-background);
}

.manager {
  margin: 20px 0px;
}

.title-text {
  white-space: pre-line;
  letter-spacing: 2px;
  line-height: 1.3;
  font-size: 13px;
}

.submit-button,
.form-group select,
.form-group input,
.form-group textarea {
  letter-spacing: 2px;
  text-transform: uppercase;
  border: 2px solid var(--dark-color-background);
  width: 100%;
  margin: 10px 0px;
  box-sizing: border-box;
  background-color: var(--dark-color-font);
  font-size: 14px;
}

.form-group textarea {
  padding-top: 50px;
  text-align: center;
}

.form-group textarea:focus {
  padding-top: 0px;
  text-align: center;
}

.submit-button {
  background-color: var(--dark-color-background);
  color: var(--dark-color-font);
  font-size: 15pt;
  line-height: 30pt;
}

.submit-button,
.form-group select,
.form-group input {
  text-align: center;
  height: 50px;
}

.submit-button:hover {
  color: var(--dark-color-background);
  background-color: var(--dark-color-font);
}

iframe {
  width: 100%;
  height: 400px;
  border: 1px solid var(--dark-color-background);
}


@media (max-width: 968px) {
  .main-column {
    width: 80%;
  }

  .p-email {
    font-size: 9px;
  }

  .title {
    margin: 10px 0px;
    font-size: 16pt;
  }

  .block {
    margin: 0px 0px;
    padding: 10px 0px;
    flex-direction: column;
  }

  .column {
    width: 100%;
    margin: 5px 5px;
    padding: 0px 0px;
  }

  .vertical-line {
    width: 100%;
    height: 1px;
    margin: 5px auto;
  }

  .left-column {
    height: 100%;
  }

  .left-column .logos a {
    height: 40px;
    margin-bottom: 0px;
    padding: 0px 0px;
  }

  .left-column .p-email {
    height: 100%;
    margin: 0px 0px;
  }
  .right-column  .logos .title-text{
    height: 100%;
  }
  .manager {
    margin: 0px 0px;
  }

  .manager p {
    margin: 0px 0px;
  }

  .right-column p {
    margin: 10px 0px;
  }
}
</style>
